export default `
<!DOCTYPE html>
<html>
  <head>
    <style>
      /* vietnamese */
      @font-face {
        font-family: 'Dancing Script';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/dancingscript/v25/If2cXTr6YS-zF4S-kcSWSVi_sxjsohD9F50Ruu7BMSo3Rep8ltA.woff2) format('woff2');
        unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
      }
      /* latin-ext */
      @font-face {
        font-family: 'Dancing Script';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/dancingscript/v25/If2cXTr6YS-zF4S-kcSWSVi_sxjsohD9F50Ruu7BMSo3ROp8ltA.woff2) format('woff2');
        unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
      }
      /* latin */
      @font-face {
        font-family: 'Dancing Script';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/dancingscript/v25/If2cXTr6YS-zF4S-kcSWSVi_sxjsohD9F50Ruu7BMSo3Sup8.woff2) format('woff2');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }
      /* latin-ext */
      @font-face {
        font-family: 'Meie Script';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/meiescript/v21/_LOImzDK7erRjhunIspaMgxu7oTQ0Q.woff2) format('woff2');
        unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
      }
      /* latin */
      @font-face {
        font-family: 'Meie Script';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/meiescript/v21/_LOImzDK7erRjhunIspaMgxg7oQ.woff2) format('woff2');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }
      /* latin-ext */
      @font-face {
        font-family: 'Sacramento';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/sacramento/v15/buEzpo6gcdjy0EiZMBUG4CMf_exL.woff2) format('woff2');
        unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
      }
      /* latin */
      @font-face {
        font-family: 'Sacramento';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url(https://fonts.gstatic.com/s/sacramento/v15/buEzpo6gcdjy0EiZMBUG4C0f_Q.woff2) format('woff2');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }

      @page { size: auto;  margin: 0mm; }

      * {
        font-family: Roboto, sans-serif;
      }

      .dados {
        display: table;
        width: 100%;
      }

      .dados-cell {
        display: table-cell;
        position: relative;
      }

      .dadosValue {
        margin-bottom: 3px;
      }

      .spacing-1 {
        padding: 8px;
      }

      .colspan-1 {
        width: 25%;
      }

      .colspan-2 {
        width: 50%;
      }

      .colspan-3 {
        width: 75%;
      }

      .colspan-4 {
        width: 100%;
      }

      .table {
        width: 100%;
        border-collapse: collapse;
        margin-bottom: 20px;
      }

      .tr-head {
        font-weight: bold;
        font-size: 14px;
      }

      .th,
      .td {
        border-bottom: 1px solid #000;
        margin-bottom: 3px;
        text-align: left;
      }

      .tr-body:last-child .td {
        border: none;
      }

      .th {
        border: none;
      }

      .labelContrato {
        font-size: 11px;
        font-weight: bold;
        color: #000000;
      }

      .label {
        font-size: 14px;
        font-weight: bold;
        color: #000000;
      }

      .valueContrato {
        font-size: 16px;
        font-weight: bold;
      }

      .value {
        font-size: 14px;
        margin-left: 5px;
      }

      .sessao:first-child {
        margin-top: 35px;
      }

      .sessao {
        position: relative;
        margin-top: 15px;
      }

      .tituloSessao {
        font-weight: 500;
        font-size: 20px;
        line-height: 23px;
        letter-spacing: 0.15px;
      }

      .divisor-sessao-contratada,
      .divisor {
        height: 1.5px;
        background: #000000;
        border: none;
        margin-bottom: 15px;
      }

      .divisor-sessao-contratada {
        width: 600px;
        margin-left: 0px;
      }

      .aceito {
        font-size: 11px;
        line-height: 24px;

        display: flex;
        align-items: center;

        color: #000000;
      }

      .newPage {
        page-break-before: always;
      }

      .newPageAfter {
        page-break-after: always;
      }

      .assinatura {
        margin-bottom: 20px;
      }

      .assinatura-titulo {
        margin-bottom: 100px;
      }

      .assinatura-titulo-label,
      .assinatura-titulo-data {
        font-size: 12px;
        margin: 0;
      }

      .file-history,
      .file-history-label {
        font-size: 10px;
        margin: 0;
      }

      .person-info {
        margin: 0;
        text-align: center;
        font-size: 16px;
      }

      .sacramento,
      .meie-script,
      .dancing-script {
        font-size: 30px;
        text-align: center;
        margin: 0;
      }

      .sacramento {
        font-family: 'Sacramento';
      }

      .meie-script {
        font-family: 'Meie Script';
      }

      .dancing-script {
        font-family: 'Dancing Script';
      }

      .img-assinatura {
        text-align: center;
        position: relative;
      }

      .src-assinatura {
        text-align: center;
        max-width: 250px;
      }

      .centralize-assinatura {
        position: absolute;
        bottom: 0;
        width: 100%;
      }
      .logo {
        position: absolute;
        left: 10%;
        top: -65px;
        background-color: #fff;
        width: 150px;
        padding-left: 5px;
      }
      .termos {
        padding: 15px 0;
      }

      .page {
        height: 10.5in;
      }

      .pageParcelas {
        height: 11in;
      }

      .pageTermos {
        height: 11in;
      }

      .lastPageTermos {
        height: 100%;
      }

      .pageAssinaturas {
        height: 7in;
      }
    </style>
  </head>
  <body class="pdfcontent">
    <div class="page">
      <div class="sessao">
        <p class="tituloSessao">Dados da Contratada</p>
        <hr class="divisor-sessao-contratada" />
      </div>

      <div class="dados">
        <div class="dados-cell colspan-2 label">Razão social</div>
        <div class="dados-cell colspan-1 label">Nome fantasia</div>
        <div class="dados-cell colspan-1 label">
          <img
            class="logo"
            src="{{dadosContratada.logo}}"
          />
        </div>
      </div>
      <div class="dados dadosValue">
        <div class="dados-cell colspan-2 value">
          {{dadosContratada.razao}}
        </div>
        <div class="dados-cell colspan-1 value">{{dadosContratada.fantasia}}</div>
        <div class="dados-cell colspan-1 value"></div>
      </div>
      {{{htmlSessaoDadosContratada}}}

      <div class="sessao">
        <p class="tituloSessao">Dados do Contratante (Titular)</p>
        <hr class="divisor" />
      </div>
      {{{htmlSessaoDadosTitular}}}

      <div class="sessao">
        <p class="tituloSessao">Endereço do Contratante (Titular)</p>
        <hr class="divisor" />
      </div>
      {{{htmlSessaoDadosEnderecoTitular}}}

      <div class="sessao">
        <p class="tituloSessao">Dados do Contratante (Aluno)</p>
        <hr class="divisor" />
      </div>
      {{{htmlSessaoDadosluno}}}

      <div class="sessao">
        <p class="tituloSessao">Endereço do Contratante (Aluno)</p>
        <hr class="divisor" />
      </div>
      {{{htmlSessaoDadosEnderecoAluno}}}
    </div>
    <div class="page">
      <div class="sessao">
        <p class="tituloSessao">{{tituloSessaoDadosCurso}}</p>
        <hr class="divisor" />
      </div>

      {{{htmlSessaoDadosCurso}}}

      <div class="sessao">
        <p class="tituloSessao">Turma e Matérias</p>
        <hr class="divisor" />
      </div>
      {{{htmlSessaoTurmasMaterias}}}

      {{{htmlHeaderMatriculaMaterial}}}
      {{{htmlSessaoValoresMatricula}}}
      {{{htmlSessaoValoresMaterial}}}
    </div>
    <div class="pageParcelas">
      <div class="sessao">
        <p class="tituloSessao">Valores das Parcelas</p>
        <hr class="divisor" />
      </div>
      <div>
        <table class="table">
          <thead>
            <tr class="tr-head">
              <th class="th">Tipo</th>
              <th class="th">Mensalidade</th>
              <th class="th">Vencimento</th>
              <th class="th">Valor</th>
              <th class="th">Desconto até a data</th>
            </tr>
          </thead>
          <tbody>
            {{#dadosCurso.parcelas}}
              <tr class="tr-body">
                <td class="td">{{tipo}}</td>
                <td class="td">{{parcela}}</td>
                <td class="td">{{vencimento}}</td>
                <td class="td">{{valor}}</td>
                <td class="td">{{calculo}}</td>
              </tr>
            {{/dadosCurso.parcelas}}
          </tbody>
        </table>
      </div>
      {{{ htmlDescontosParcelas }}}
    </div>
    <div>
      <p class="tituloSessao">Termos do Contrato</p>
      <hr class="divisor" />
    </div>
    <div class="termos">{{{termosUso}}}</div>
    <div class="pageAssinaturas">
      <div class="sessao">
        <div class="assinatura-titulo">
          <p class="assinatura-titulo-label">Autenticação Eletrônica</p>
          <p class="assinatura-titulo-data">{{dataAceite}}</p>
        </div>
      </div>
      <div class="dados">
        <div class="dados-cell colspan-2 img-assinatura">
          {{{clientSignature}}}
          <p>Assinatura realizada através do <strong>Portal do Aluno</strong>, por <strong>{{dadosAluno.nome}}</strong></p>
          <p>a partir do endereço de IP <strong>"{{clientIP}}"</strong></p>.
          <p>Em <strong>{{dataAceite}}</strong>.</p>
        </div>
        <div class="dados-cell colspan-2 img-assinatura">
          <div class="centralize-assinatura"><img class="src-assinatura" src="{{dadosContratada.assinatura1}}" /></div>
        </div>
      </div>
      <div class="dados assinatura">
        <div class="dados-cell colspan-2 spacing-1">
          <hr class="divisor" />
          <div>
            <p class="person-info">{{dadosAluno.nome}}</p>
            <p class="person-info">CPF: {{dadosAluno.cgc_cpf}}</p>
          </div>
        </div>
        <div class="dados-cell colspan-2 spacing-1">
          <hr class="divisor" />
          <div>
            <p class="person-info">Razão Social: {{dadosContratada.razao}}</p>
            <p class="person-info">CNPJ: {{dadosContratada.cnpj}}</p>
          </div>
        </div>
      </div>
      <p class="file-history-label">Histórico</p>
      <hr class="divisor" />
      <p class="file-history">
        {{dataAceite}} - Aceite do contrato realizado através do Portal do Aluno.
      </p>
    </div>
  </body>
</html>
`;
